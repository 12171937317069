import React from 'react';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="hero">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <h1>This QR-Code could not be found.</h1>
            <p>Please try again by scanning the original QR-Code.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
